import React from "react";
import { Table, Tag, Button, Form, Input, notification } from "antd";

export function Clients({ onClientsUpdate, clients }) {
  const [form] = Form.useForm();
  const { Column } = Table;
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type, msg, desc) => {
    api[type]({
      message: msg,
      description: desc,
    });
  };

  const onFinish = (values) => {
    onClientsUpdate(values);
    openNotification("success", "Success!", "Client has been added");
    form.resetFields();
  };
  const onFinishFailed = () => {
    openNotification("error", "Failed!", "Something went wrong");
  };

  return (
    <div style={{ marginRight: "5rem" }}>
      {contextHolder}
      <Form
        name="clientsForm"
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 800,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelAlign="left"
      >
        <Form.Item
          label="Client Name"
          name="clientName"
          rules={[
            {
              required: true,
              message: "Please input the name",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Client Group"
          name="clientGroup"
          rules={[
            {
              required: false,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="YouTube Channel"
          name="channel"
          rules={[
            {
              required: true,
              message: "Please input link to the channel or a channel id",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item>
      </Form>
      <hr />
      <Table dataSource={clients}>
        <Column title="Client Name" dataIndex="clientName" key="clientName" />
        <Column
          title="Client Group"
          dataIndex="clientGroup"
          key="clientGroup"
          render={(groups) => (
            <>
              {groups.split(",").map((group) => (
                <Tag color="blue" key={group}>
                  {group}
                </Tag>
              ))}
            </>
          )}
        />
        <Column title="YouTube Channel" dataIndex="channel" key="channel" />
      </Table>
    </div>
  );
}
