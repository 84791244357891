import React, { useState } from "react";
import { Table, Button, Form, Input, Radio, Tag, notification } from "antd";

export function Keywords() {
  const [form] = Form.useForm();
  const [keywords, setKeywords] = useState([
    { value: "TEST", usage: "one" },
    { value: "TEST2", usage: "multi" },
  ]);
  const { Column } = Table;
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type, msg, desc) => {
    api[type]({
      message: msg,
      description: desc,
    });
  };

  const onFinish = (values) => {
    setKeywords([...keywords, values]);
    openNotification("success", "Success!", "Keyword has been added");
    form.resetFields();
  };

  const onFinishFailed = () => {
    openNotification("error", "Failed!", "Something went wrong");
  };

  return (
    <div>
      {contextHolder}
      <Form
        name="keywordsForm"
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 800,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelAlign="left"
      >
        <Form.Item
          label="Value"
          name="value"
          rules={[
            {
              required: true,
              message: "Please add a value!",
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Type" name="usage">
          <Radio.Group>
            <Radio value="one"> One-time Usage </Radio>
            <Radio value="multi"> Multi-time Usage </Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Add a Keyword
          </Button>
        </Form.Item>
      </Form>
      <hr />
      <Table dataSource={keywords}>
        <Column title="Value" dataIndex="value" key="value" />
        <Column
          title="Type"
          dataIndex="usage"
          key="usage"
          render={(value) => (
            <>
              {value === "one" ? (
                <Tag color="magenta" key={"one"}>
                  One-time usage
                </Tag>
              ) : (
                <Tag color="purple" key={"multi"}>
                  Multi-time usage
                </Tag>
              )}
            </>
          )}
        />
      </Table>
    </div>
  );
}
