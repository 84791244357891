import React, { useState } from "react";
import Logo from "./images/logo.png";
import { Clients } from "./components/Clients";
import { Allowlists } from "./components/Allowlists";
import { ClaimRelease } from "./components/ClaimRelease";
import { Keywords } from "./components/Keywords";
import { Layout, Col, Row, Card } from "antd";

function App() {
  const [clients, setClients] = useState([
    {
      clientName: "Test Client 1",
      clientGroup: "Group A",
      channel: "https://www.youtube.com/@test1",
    },
    {
      clientName: "Test Client 2",
      clientGroup: "Group A",
      channel: "https://www.youtube.com/@test2",
    },
    {
      clientName: "Test Client 3",
      clientGroup: "Group B",
      channel: "https://www.youtube.com/@test3",
    },
  ]);
  const [activeTab, setActiveTab] = useState("tab1");
  const { Header, Content, Footer } = Layout;

  const onClientsUpdate = (newClient) => {
    setClients([...clients, newClient]);
  };

  const onTabChange = (key) => {
    setActiveTab(key);
  };

  const tabList = [
    {
      key: "tab1",
      tab: "Create Allowlist",
    },
    {
      key: "tab2",
      tab: "Release Claims",
    },
    {
      key: "tab3",
      tab: "Add Keyword",
    },
  ];

  const tabMapping = {
    tab1: <Allowlists clients={clients} />,
    tab2: <ClaimRelease />,
    tab3: <Keywords />,
  };

  return (
    <div>
      <Layout style={{ height: "100vh" }}>
        <Header
          style={{
            position: "sticky",
            top: 0,
            zIndex: 1,
            width: "100%",
            display: "flex",
            alignItems: "center",
            backgroundColor: "#191919",
          }}
        >
          <img
            alt="Logo"
            src={Logo}
            height="60"
            className="d-inline-block align-top"
          />
        </Header>
        <Content
          className="site-layout"
          style={{
            padding: "5%",
          }}
        >
          <Row>
            <Col span={12}>
              <Clients onClientsUpdate={onClientsUpdate} clients={clients} />
            </Col>
            <Col span={12}>
              {
                <Card
                  style={{ width: "100%" }}
                  tabList={tabList}
                  activeTabKey={activeTab}
                  onTabChange={onTabChange}
                >
                  {tabMapping[activeTab]}
                </Card>
              }
            </Col>
          </Row>
        </Content>
        <Footer
          style={{
            textAlign: "center",
          }}
        >
          HAAWK ©2023
        </Footer>
      </Layout>
    </div>
  );
}

export default App;
