import React, { useState } from "react";
import { Table, Button, Form, Input, notification } from "antd";

export function ClaimRelease() {
  const [form] = Form.useForm();
  const [claims, setClaims] = useState([
    { url: "https://www.youtube.com/watch?v=flSvRm_MvjI" },
    { url: "https://www.youtube.com/watch?v=jlSvWeQdfs_vjI" },
  ]);
  const { Column } = Table;
  const [api, contextHolder] = notification.useNotification();

  const openNotification = (type, msg, desc) => {
    api[type]({
      message: msg,
      description: desc,
    });
  };

  const onFinish = (values) => {
    setClaims([...claims, values]);
    openNotification(
      "success",
      "Success!",
      "Claim release request has been added"
    );
    form.resetFields();
  };

  const onFinishFailed = () => {
    openNotification("error", "Failed!", "Something went wrong");
  };

  return (
    <div>
      {contextHolder}
      <Form
        name="claimReleaseForm"
        form={form}
        labelCol={{
          span: 8,
        }}
        wrapperCol={{
          span: 16,
        }}
        style={{
          maxWidth: 800,
        }}
        initialValues={{
          remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        labelAlign="left"
      >
        <Form.Item
          label="Video URL"
          name="url"
          rules={[
            {
              required: true,
              message: "Please input a URL!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          wrapperCol={{
            offset: 8,
            span: 16,
          }}
        >
          <Button type="primary" htmlType="submit">
            Release a Claim
          </Button>
        </Form.Item>
      </Form>
      <hr />
      <Table dataSource={claims}>
        <Column title="Video URL" dataIndex="url" key="url" />
      </Table>
    </div>
  );
}
