import React, { useEffect, useState } from "react";
import { Select, DatePicker, Button, Form, Table, notification } from "antd";

export function Allowlists({ clients }) {
  const [client, setClient] = useState("");
  const [date, setDate] = useState();
  const [options, setOptions] = useState([]);
  const [allowlists, setAllowlists] = useState([
    {
      clientName: "Test Client 1",
      startDate: new Date("2023-01-01"),
      endDate: new Date("2023-02-02"),
    },
    {
      clientName: "Test Client 2",
      startDate: new Date("2023-03-03"),
      endDate: new Date("2023-04-04"),
    },
  ]);
  const [api, contextHolder] = notification.useNotification();
  const { RangePicker } = DatePicker;

  useEffect(() => {
    let arr = [];
    clients.map((item) =>
      arr.push({ value: item.clientName, label: item.clientName })
    );
    setOptions(arr);
  }, [clients]);

  const openNotification = (type, msg, desc) => {
    api[type]({
      message: msg,
      description: desc,
    });
  };

  const onChange = (value) => {
    setClient(value);
  };

  const onChangeDate = (value) => {
    setDate(value);
  };

  const onFinish = () => {
    const newAllowlist = {
      clientName: client,
      startDate: date[0].$d,
      endDate: date[1].$d,
    };

    setAllowlists([...allowlists, newAllowlist]);
    openNotification("success", "Success!", "Allowlist entry has been added");
  };

  const onFinishFailed = () => {
    openNotification("error", "Failed!", "Something went wrong");
  };

  const columns = [
    {
      title: "Client",
      dataIndex: "clientName",
      key: "client",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      key: "startDate",
      render: (text) => <a>{text.toLocaleDateString("en-US")}</a>,
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
      render: (text) => <a>{text.toLocaleDateString("en-US")}</a>,
    },
  ];

  return (
    <div>
      {contextHolder}
      {clients.length > 0 ? (
        <div>
          <Form
            name="allowlist"
            style={{ maxWidth: 800 }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout={"inline"}
          >
            <Form.Item
              rules={[{ required: true, message: "Please choose a client!" }]}
            >
              <Select
                defaultValue="Choose Client"
                style={{ width: 140 }}
                onChange={onChange}
                options={options}
              />
            </Form.Item>

            <Form.Item
              rules={[
                { required: true, message: "Please choose effective date!" },
              ]}
            >
              <RangePicker onChange={onChangeDate} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
          <hr />
          <Table columns={columns} dataSource={allowlists} />
        </div>
      ) : (
        <p>No clients found</p>
      )}
    </div>
  );
}
